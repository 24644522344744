export const invoiceLogos = ['Digimedia', 'DATA', 'DF', 'ECS', 'PAS', 'PHARMA', 'SMS']
export const hasSalesProgramSplit = true
export const HORUS_CK = "ck_orxermtjbcnbcmc"
export const LABEL_CRM = "BOP"
export const LABEL_EVENT = "Event"
export const LABEL_QNOTE = "Quick note"
export const DEFAULT_VALUE_LONG_NOTE = ``
export const SUCC_URL = "https://crm.digimedia.be/success"
export const STRIPE_KEY = "pk_live_51QexCWAlcOHcSDqOKYwQ8pxIqz2zw5uTJCJ4ixC0Ya74xdh34yCYrcZcz68mtdZoAp0GqfxnqyjsKbRbhODNwPwz00FCYQiV1J"
export const REGISTRATION_FORM_CLASS = "black"
export const TERMS_CAPTION = 'I Accept the general Terms of Sales'
export const GUEST_CAPTION = 'Guest'
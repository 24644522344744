import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { apiRequest, getInvoiceForSale } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";
import {LABEL_QNOTE} from "../econf/cnf"

import moment from "moment"
import { dynamicSortMultiple, oValues, productValueLabel } from "./_tools";

class SalesBrowser extends Component {
    state = {
        cFilter: "",
        companyFilter: "",
        noteFilter: "",
        idFilter: "",
        byFilter: {value:-1, label:"-"},
        productsFilter: [],
        dateFilterFrom: null,
        dateFilterFromT: null,
        dateFilterTo: null,
        dateFilterToT: null,
        checkedFilter: {value:0, label:"All"},
        paidFilter: {value:0, label:"All"},
        sentFilter: {value:0, label:"All"},
        sortBy: {value:0, label:"Date"},
    }

    salesList = []
    usersList = []
    productFilterList = []

    numberWithSpaces(x) {
        if (!x)
            return x
        if (Math.abs (x) < 0.01)
            return 0
        
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        if ((parts.length > 1) && (parts[1].length > 2))
            parts[1] = parts[1].substr (0,2)
        return parts.join(".");
    }

    differenceInDaysFromTodayIsIn(d0Str, v1, v2) {
        var d0 = moment (d0Str, 'DD-MM-YYYY')
        var d1 = moment ()
        d0.set({hour:12,minute:0,second:0,millisecond:0})
        d1.set({hour:12,minute:0,second:0,millisecond:0})
        var diff = d0.diff(d1, 'days')
        var isIn = true
        if (v1 !== null)
            isIn = isIn && (diff >= v1)
        if (v2 !== null)
            isIn = isIn && (diff <= v2)
        return isIn
      }

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getSalesConfig'}))
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    sortResult (el1, el2, multiplier = 1) {
        if (!el1)
            return -multiplier
        if (!el2)
            return multiplier
        if (el1 < el2)
            return -multiplier
        if (el1 > el2)
            return multiplier
        return 0
    }


    render () {
        var contents = null
        var that = this
        this.salesList = oValues (this.props.sales)
        this.usersList = oValues(this.props.users).sort(dynamicSortMultiple ('-enabled', '*fullName')).map (function (el) {
            return {value:el.id, label: el.enabled ? el.fullName : (el.fullName + ' (arch)')}
        }),
        this.productFilterList = oValues (this.props.productConfig.productsDic).map (function (el) {
            return {value:el.id, label: el.fullName}
        }).concat (oValues (this.props.productConfig.editionsDic).map (function (el) {
            return {value:-el.id, label: el.shortName}
        })).sort(dynamicSortMultiple ('*label'))

        var selOptions = {
            'by': this.usersList,
            'product': this.productFilterList
        }

        var cnt = 0
        var total = 0
        var totalTVAC = 0
        
        var lines = this.salesList.sort(function(t1,t2) {
            if (that.state.sortBy.value === 0)
                return that.sortResult(moment(t1.transactionDate, 'DD-MM-YYYY') + t1.id, moment(t2.transactionDate, 'DD-MM-YYYY') + t2.id, -1)
            if (that.state.sortBy.value === 1)
                return that.sortResult(t1.agreedPrice, t2.agreedPrice, -1)
            if (that.state.sortBy.value === 2)
                return that.sortResult((t1.idCompany > 0) ? that.props.contactConfig.companiesDic[t1.idCompany].name.toLowerCase():null, (t2.idCompany > 0) ? that.props.contactConfig.companiesDic[t2.idCompany].name.toLowerCase():null)
            if (that.state.sortBy.value === 3)
                return that.sortResult(t1.id, t2.id, -1)
            if (that.state.sortBy.value === 4)
                return that.sortResult(t1.invoiceRef, t2.invoiceRef, -1)
        }).map (function (p, ic) {
            if ((that.state.cFilter.length > 0) && ((p.idContact <= 0) || (! that.props.contactConfig.contactsDic[p.idContact].fullName.toLowerCase().includes (that.state.cFilter.toLowerCase()) )))
                return null
            if ((that.state.companyFilter.length > 0) &&  ((p.idCompany < 0) || ((! that.props.contactConfig.companiesDic[p.idCompany].shortName.toLowerCase().includes (that.state.companyFilter.toLowerCase())) && ((! that.props.contactConfig.companiesDic[p.idCompany].name.toLowerCase().includes (that.state.companyFilter.toLowerCase()))))))
                return null
            if ((that.state.noteFilter.length > 0) &&  ((!p.note) || (! p.note.toLowerCase().includes (that.state.noteFilter.toLowerCase()) )))
                return null
            if ((that.state.idFilter.length > 0) && (! p.id.toString().includes (that.state.idFilter) ))
                return null

            if (that.state.productsFilter.length > 0)
            {
                var hasSelProduct = false
                for (var pF of that.state.productsFilter)
                { 
                    hasSelProduct = hasSelProduct || (p.oneTimeProduct && ( p.oneTimeProduct.toLowerCase().includes (pF.label.toLowerCase())) )
                    for (var pp of p.products)
                        hasSelProduct = hasSelProduct || ( productValueLabel(pp.idProduct, that.props.productConfig).label.toLowerCase().includes (pF.label.toLowerCase()) )
                }
                if (!hasSelProduct)
                    return null
            }
        
            if ((that.state.byFilter.label.length > 1) && ((p.idBy < 0) || (! that.props.users[p.idBy].fullName.toLowerCase().includes (that.state.byFilter.label.toLowerCase()) ))  && ((!p.historicBy) || (! p.historicBy.toLowerCase().includes (that.state.byFilter.label.toLowerCase()) )))
                return null
            if ((that.state.dateFilterFrom) && ((!p.transactionDate) || (moment (p.transactionDate, 'DD-MM-YYYY').toDate() < that.state.dateFilterFrom)))
                return null
            if ((that.state.dateFilterTo) && ((!p.transactionDate) || (moment (p.transactionDate, 'DD-MM-YYYY').toDate() > that.state.dateFilterTo)))
                return null
            if (((that.state.checkedFilter.value === 1) && (!p.invoiceChecked)) || ((that.state.checkedFilter.value === 2) && (p.invoiceChecked)))
                return null
            if (((that.state.paidFilter.value === 1) && (!(p.invoicePaid || p.cashPaid))) || ((that.state.paidFilter.value === 2) && ((p.invoicePaid || p.cashPaid || p.willCashPaid))) || ((that.state.paidFilter.value === 3) && (!p.willCashPaid))  || ((that.state.paidFilter.value === 4) && (!p.invoiceMatched))  )
                return null    
            if (((that.state.sentFilter.value === 1) && (!(p.invoiceSent))) || ((that.state.sentFilter.value === 2) && ((p.invoiceSent))))
                return null    
            cnt += 1    
            total += p.agreedPrice 
            totalTVAC += p.agreedPriceTVAC

            var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : {}

            return <tr key={"sale"+ic} role="button" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": p.id})}}>
                <td></td>
                <td>{p.transactionDateDM} {p.transactionDateY}</td>
                <td>{p.id}</td>
                <td>{p.products.map (function (pp, icc) { return pp.idProduct > 0 ? (productValueLabel(pp.idProduct, that.props.productConfig).label + " ") : "" }) } {p.oneTimeProduct ? p.oneTimeProduct : ""}</td>
                <td className="text-end">{that.numberWithSpaces(p.agreedPrice)}</td>
                <td className="text-end">{that.numberWithSpaces(p.agreedPriceTVAC)}</td>
                <td>{that.props.contactConfig.companiesDic[p.idCompany] ? that.props.contactConfig.companiesDic[p.idCompany].shortName : ""}</td>
                <td>{pContact.fullName}</td>
                <td>{p.note}</td>
                <td>{(p.idBy > 0) ? that.props.users[p.idBy].fullName : p.historicBy }</td>
                <td onClick={(e)=>{
                    if (((that.props.profile.isAdmin) || (that.props.profile.hasMiniDashboard) || (that.props.profile.id === p.idBy)) && p.horusTransactionId)
                    {
                        e.stopPropagation ()
                        that.props.dispatch (getInvoiceForSale (p.id, "invoice_" + p.id + '.pdf'))
                    }
                }}>{p.invoiceRef}</td>
                <td>{p.invoiceSent ? <i className={"fs-4 bi-check2"}>
                        <div className="sendDetails">
                            {p.invoiceSendAttempts.map (function (sa, icsa) { return <div key={"sa"+ic+"_"+icsa}>{sa['date']}</div>})}
                        </div>
                    </i>:null }</td>
                <td>{(p.invoicePaid || p.cashPaid) ? <i className={"fs-4 bi-check2"}></i>:(p.invoiceMatched ? <i className={"fs-4 bi-battery-half"}></i>: (p.willCashPaid ? <i className={"fs-4 bi-hourglass"}></i>:null)) }</td>
                <td>{p.revertedOf > 0 ? <div>Rev. of {p.revertedOf}</div>:null}
                    {p.revertedBy.length > 0 ? <div>Rev. by {p.revertedBy}</div>:null}
                 </td>
                {that.props.profile.platformParams && that.props.profile.platformParams.invoiceReminderAvailable ? <td>{p.invoiceSent && (!(p.invoicePaid || p.cashPaid || p.willCashPaid)) && (!((p.invoiceSendAttempts.length > 0) && (that.differenceInDaysFromTodayIsIn (p.invoiceSendAttempts[p.invoiceSendAttempts.length - 1].date, -7, 0 )) )) ? <button className="btn btn-secondary" onClick={(e)=>{
                    e.stopPropagation();
                    var result = confirm("Are you sure you want to send a reminder e-mail to the client?");
                    if (result) {
                        that.props.dispatch (apiRequest({'endpoint': 'sendSaleReminder', "saleId": p.id})); 
                    }    
                }}>Reminder</button>:null}</td>:null}
            </tr>
        })
        contents = <div className="mt-3">
            <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": 0})}}>+ Add</Button>
            <h3 className="transactionsTotal">Transactions ({cnt}{that.props.isExAdmin ? <span> - {that.numberWithSpaces(total)} eur; {that.numberWithSpaces(totalTVAC)} tvac</span>:null})</h3>
            <div className="filters">
                <h4>Filters:</h4>
                <div className="mb-3">
                    <div className="me-3 d-inline-block">
                        <DatePicker     
                            selected={that.state.dateFilterFrom} 
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => { that.setState ({dateFilterFrom:date, dateFilterFromT: date ? date.getTime(): date}); }}
                            placeholderText="From date"/>
                    </div>
                    <div className="me-3 d-inline-block">
                        <DatePicker
                            selected={that.state.dateFilterTo} 
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => { that.setState ({dateFilterTo:date, dateFilterToT: date.getTime()}); }}
                            placeholderText="To date"/>
                    </div>
                </div>
                <div className="mb-3">
                    <input
                        className="form-control me-3 d-inline-block"
                        name="idFilter"
                        onChange={that.handleInputChange}
                        value={that.state["idFilter"]}
                        type="text"
                        placeholder="ID"
                    />
                    <input
                        className="form-control me-3 d-inline-block"
                        name="companyFilter"
                        onChange={that.handleInputChange}
                        value={that.state["companyFilter"]}
                        type="text"
                        placeholder="Company"
                    />
                    <input
                        className="form-control me-3 d-inline-block"
                        name="cFilter"
                        onChange={that.handleInputChange}
                        value={that.state["cFilter"]}
                        type="text"
                        placeholder="Contact"
                    />
                    
                    <div className="d-inline-block me-3 ms-3 w300px"><Creatable  
                        isMulti={true}
                        menuPosition="fixed"
                        name="productsFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["productsFilter"]}
                        options={[{value:0, label:"-"}].concat (selOptions['product'])}
                        placeholder="Product"
                    /></div>
                    
                    By 
                    <div className="d-inline-block ms-3 w300px"><Creatable  
                        menuPosition="fixed"
                        name="byFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["byFilter"]}
                        options={[{value:0, label:"-"}].concat (selOptions['by'])}
                    /></div>

                    <input
                        className="form-control ms-3 d-inline-block"
                        name="noteFilter"
                        onChange={that.handleInputChange}
                        value={that.state["noteFilter"]}
                        type="text"
                        placeholder={LABEL_QNOTE}
                    />
                    
                </div>
                <div className="mb-3">
                    Checked: 
                    <Select 
                        menuPosition="fixed"
                        className="d-inline-block ms-3"
                        name="checkedFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["checkedFilter"]}
                        options={[{value:0, label:"All"},{value:1, label:"Yes"},{value:2, label:"No"}]}
                    />
                </div>
                <div className="mb-3">
                    Paid: 
                    <Select 
                        menuPosition="fixed"
                        className="d-inline-block ms-3 me-3"
                        name="paidFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["paidFilter"]}
                        options={[{value:0, label:"All"},{value:1, label:"Yes"},{value:2, label:"No"}, {value:3, label:"Pay on receival"}, {value:4, label:"Partially paid"}]}
                    />
                    Sent: 
                    <Select 
                        menuPosition="fixed"
                        className="d-inline-block ms-3"
                        name="sentFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["sentFilter"]}
                        options={[{value:0, label:"All"},{value:1, label:"Yes"},{value:2, label:"No"}]}
                    />
                </div>
                <div className="mb-3">
                    Sort by: 
                    <Select 
                        menuPosition="fixed"
                        className="d-inline-block ms-3"
                        name="sortBy"
                        onChange={that.handleSelectChange}
                        value={that.state["sortBy"]}
                        options={that.props.isExAdmin ? [{value:0, label:"Date"},{value:1, label:"Amount"},{value:2, label:"Company"}, {value:3, label:"ID"}, {value:4, label:"Invoice NB"}]:[{value:0, label:"Date"},{value:2, label:"Company"}, {value:3, label:"ID"}, {value:1, label:"Amount"}]}
                    />
                </div>
                
            </div>
            <Table responsive className="table-hover salesTable sticky">
                <thead>
                    <tr>
                        <th></th>
                        <th>Date</th>
                        <th>ID</th>
                        <th>Products</th>
                        <th className="text-end">HTVA</th>
                        <th className="text-end">TVAC</th>
                        <th>Company</th>
                        <th>Contact</th>
                        <th>{LABEL_QNOTE}</th>
                        <th>By</th>
                        <th>Invoice&nbsp;Nb.</th>
                        <th>Sent</th>
                        <th>Paid</th>
                        <th>Links</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {lines}
                </tbody>
            </Table>
        </div>
        
        return <div className={"salesBrowser "  + (that.props.isExAdmin && (!that.props.interface.editingSaleId) ? "doPrint":"noPrint")}>
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        sales: state.data.sales,
        isAdmin: state.data.profile.isAdmin,
        isExAdmin: state.data.profile.isExAdmin,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig,
        users: state.data.users,
        profile: state.data.profile
    }
}

export default connect(mapStateToProps)(SalesBrowser)